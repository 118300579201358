import axios from "axios"

export default {
  async purchase(paymentObj) {
    try {
      const response = await axios.post(
        `${process.env.PRODUCT_API_URL}/purchase`,
        paymentObj,
      )
      return response
    } catch(error) {
      return error
    }
  },
}

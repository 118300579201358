/* eslint-disable max-len */

const terms = `# Terms & Conditions

## INSURING AGREEMENT

The Insurer will indemnify the Insured in an amount not exceeding the amount of insurance as stated in the Declarations, for the costs incurred in providing the insured prize value as stated on the issued policy, to the Participant who during the policy period makes the first Hole-in-one on the Insured Hole in the Covered Tournament. All subsequent Hole-in-ones made on the Insured hole during the policy period, will be paid a secondary prize of Twenty percent (20%) Prize Restoration which is included in all issued policies should there be a second hole-in-one at the designated hole(s) with a maximum amount of $10,000. 100% Prize Restoration is available with a 10% surcharge added to the premium, when the 100% prize restoration option is purchased then all Participants who register at Hole-in-one in the Covered Tournament will be eligible to win the secondary insured amount with a maximum amount of $50,000 should there be a second hole-in-one at the designated hole. Once the hole-in-one prize and the prize restoration is claimed the policy then becomes VOID.

## CONDITIONS

If any of the following terms/conditions have been breached either in whole or in part, the Insurer has the right to deny a claim and the Insured shall have no recourse against Elite Insurance Company and/or Wayfarer Insurance Group or any of their representatives.

The rates quoted are based on the Participant (excluding companies, partnership or other business) being an Amateur golfer, who is at least 18 years of age and who are eligible participants according to these rules. Each must be playing in a minimum group of three (no twosomes) entered and playing in a golf event, on a regular golf course grass green for which a policy has been purchased. All premium rates are based on a per golfer /per thousand dollars of prize value /at a specified hole length.

Elite Insurance Company must receive all applications for Hole-in-One insurance a minimum of three days prior to the tournament date/effective date of coverage. However, we can issue a policy up to 24 hours before the tournament date if payment is made by credit card. The Insurer must be notified in writing (fax or e-mail) a minimum of 24 hours prior to event start time of any changes made to the policy. Failure to notify the Insurer in writing of any changes will result in a denied claim and the Insured shall have no recourse against the Insurer.

A participant is defined as any individual entered and playing in the insured tournament; the named insured on the issued policy would not be eligible to claim the prize whether they are a participant in the covered tournament and/or listed as the named insured. The following participants are ineligible to qualify for or claim the contest prize: employees of the golf course where the insured tournament is being held, members, personnel, agents and representatives of Elite Insurance Company or its affiliates (defined as all of the companies affiliated with AVIVA Canada Inc.), its advertising or promotion agencies, and the suppliers of the prize, equipment and services related to this contest.

Tournament must have a minimum of 24 registered participants. A prize value of $5,000 is the maximum insured prize value for groups of forty (40) or less participants.

9 Hole Course - A target hole may only be played once per day per Participant to qualify as an Insured hole. In the case of a nine (9) hole golf course, the tee-off area must be moved to a position that is a minimum of ten (10 to 15) yards different from the first round and any subsequent rounds played that day.
No green or designated hole may be specially prepared, altered or have a pin placement as to facilitate a Hole-in-one (bowl).

All equipment used in tournament play must conform to RCGA standards. Equipment includes, but is not limited to, golf club(s), golf balls, flagstick, and cup hole.

In a case where the grade of the land on the Insured Hole, from tee box to green, is significantly elevated and it radically increases the carry of the ball from the distance indicated on the official score card then the Insured must state the yardage of actual play. The premium rate of the actual play distance must be used. In the event it is determined that the incorrect yardage and premium rate have been chosen then the claim will be denied and the Insured shall have no recourse against the Insurer or any of its representatives.

Only one original shot per Participant per Insured hole can qualify to win a Hole-in-one prize. No mulligan or practise shots are allowed. In the event the group is a threesome and a selected player is shooting for his/her absent playing partner then that Participant‘s “first shot only” is valid to qualify for a Hole-in-one prize.

Minimum required yardage for female participants is 130+ yards when playing in mixed event, if this minimum requirement cannot be met then all participants in the covered tournament must tee off from the yardage specified on the issued policy. If a women only tournament is being held, the minimum yardage requirements can be as low as 110 yards with the associated premium.

Professional golfers or golf course professionals must play from a minimum of 175 yards from tee box to flagstick on the insured hole for prize values from $5,000 to $50,000, premiums will be surcharged accordingly.

The Insured is responsible for conveying to all Participants in the Covered Tournament, all the rules and regulations as set out in this declaration. The Insured must ensure that all details provided are accurate and complete (i.e. prize value is on the correct Insured hole, hole length, monitors(s) in the correct position, etc.). It’s not the responsibility of the Insurer or its agents/brokers to verify the accuracy of the information provided by the Insured.

Elite Insurance will accept only one (1) policy per hole per day for the same hole in an Insured event.
WITNESS/HOLE MONITOR requirements for Prize values:

$0.00 - $5,000.00 - Groups of twenty four (24) to forty (40) Participants must have an independent / non-related person as their hole monitor. (Max. $5,000 prize value) - Groups of 41 and more Participants do not require a hole monitor. (Max. $5,000 prize value)

$5,000.01 - $50,000.00 - There must be a minimum of 41 Participants in the Covered Tournament. One (1) hole monitor is required and must be stationed adjacent to the green, out of harms’ way, where they can clearly see the ball go into the hole or at the tee box with full view of the designated hole. If located at the tee box and a hole-in-one occurs the monitor must accompany the group of participants to the green to witness the hole-in-one prior to the ball being removed from the designated hole.

In all cases the ball may not be removed from the hole until all group players have arrived on the green to witness the feat.

All monitors/witnesses will be appointed by the Insured and will be event non-participants, at least 18 years of age and of good moral character.

Sponsor/Insured/Participant/Witness shall indemnify Elite Insurance Company and Wayfarer Insurance Group or any of their representatives from all liability regarding the safety of all witnesses.

### POSTPONEMENT/RAIN OUT/CANCELLATION/TERMINATION:

If the Insured is forced to postpone the Covered Tournament due to inclement weather or vandalism of the Golf Course, the Insurer will amend the current Policy Period stated in the declarations to comply with the revised date on which the Tournament will be held, provided it is within the same calendar year of the original expiration date of this policy. The Insured must provide the Insurer with written notice no later than two (2) business days from the original tournament date, stating that all of the following conditions have been met: (a phone message is not a valid method of notice.)

The Covered Tournament was postponed due to inclement weather and/or vandalism of the Golf Course.
None of the Participants have made a Hole-In-one on the Selected Hole.
No more than ten (10) percent of the Participants have completed playing the Insured Hole, listed on the issued policy.

The scope of the rescheduled Covered Tournament may not be materially changed from the described coverage in the original application for Insurance, unless approved by the Insurer.
The revised date(s) of the Covered Tournament given to the Insurer must be within the same calendar year of the original expiration date of this policy.

In the event of a rescheduled date, then this information must be conveyed at the same time.
Failure to notify the Insurer in writing within two (2) days of the Covered Tournament date will negate any reschedule date and premium will not be refunded.

For full premium refund if the above noted requirements are met, we do require a letter from the golf course confirming the cancellation of the tournament.

The contract can be cancelled or terminated by the Insured prior to the policy date by submitting in writing notice to the Insurer. Such notice must be received a minimum of two (2) days prior to the start of the Covered Tournament. The Insurer will refund to the Insured the premium in full only as stated on the issue policy, failure to notify within the required time may result in the loss of the premium. Administrative fees will not be refunded. This contract may also be terminated by the Insurer with due cause.

### CLAIMS:

To report a Hole-in-one claim, please send an email to hioclaims@wayfarerinsurancegroup.com.

### INSURED REQUIREMENTS AFTER LOSS:

Notify the Insurer in writing within two (2) business days after the Covered Tournament, of the Hole-in-One. Failure to notify the Insurer in writing (a phone message is not acceptable) of a Hole-in-one within two (2) business days will result in a denied claim and the Insured will have no recourse against the Insurer.

Certification of the achievement of a Hole-in-One by a Participant in a Covered Tournament, must be made on the actual score card by the Participant, her/his playing partners and the hole monitor person(s). Submit the original score card to the Insurer within seven (7) days of the date the Hole-in-One was achieved.

Elite Insurance Hole-in-One Claims & Release forms must be used and accurately completed by all persons required by this policy declaration and received by Wayfarer Insurance Group within seven ( 7) business days of the hole-in-one in order to receive the insured prize. Names, addresses and phone numbers must be filled in on the claim forms provided. Failure to provide all required information will delay claim processing and could result in a denied claim.

If the Insured value as stated on the policy is for a specific prize such as a car, boat, travel voucher etc., the insured must verify with the insurer that the insured prize value as stated on the policy has been or will be presented to the participant who during the policy period makes the first Hole-in-one on the Insured Hole in the Covered Tournament. Once this has been verified the payment can be made to the named insured for the prize value stated on the issued policy.

Signed authorization required from the Named Insured for direct cash payment to the winner of the designated prize value.

Upon receipt of all of the above information, Elite Insurance Company will supply the claimant with an affidavit and claim form. This must be completed fully and returned to Elite Insurance within five business days. Failure to provide a completed and signed claim affidavit within the specified time will result in the claim being denied.

In the event of a Hole-in-one, the Insurer has the right to indisputably deny the claim, and the Insured shall have no recourse against the Insurer, if is discovered that:

The Insured hole is not played at the distance stated on this policy.
The Hole-in-one is achieved on a hole other than that stated as the Insured Hole # on the policy.
More Players are registered or playing in the Covered Tournament than the number of participants stated on this policy.

The Insurer shall have the right and opportunity to make an investigation as it shall deem necessary of any claim and the Insured shall cooperate with the Insurer and shall make available to the Insurer at all reasonable times, its books and records pertaining to said claims. If the Insurer is forced to defend any action brought against it by the Insured as a result of a denial of a claim made against this policy declaration then the Insured shall agree to pay any and all legal costs incurred by the Insurer in defending this action if it is ruled that the Insurer is not liable. Any action brought against the Insurer by the Insured shall be done in the jurisdiction designated by the Insurer only.

This policy does not apply to any claim arising out of fraud, collusion, dishonesty or misrepresentation involving, but not limited to, the named insured, winner of the insured prize, tournament organizer or golf course representative. Failure to comply with all Terms & Conditions will result in a claim being denied.`

export default terms

/* eslint-enable max-len */

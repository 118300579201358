import React, { useState } from "react"
import PropTypes from "prop-types"
import { KeyboardDatePicker } from "@material-ui/pickers"
import moment from "moment"
import Typography from '@material-ui/core/Typography'

const DatePicker = ({ field, form, label, className, disabled, description }) => {
  const { setFieldValue, errors } = form
  const error = errors && errors[field.name]
  const [selectedDate, handleDateChange] = useState(field.value)

  return (
    <div>
      <Typography variant="body2">{description}</Typography>
      <KeyboardDatePicker
        error={!!error}
        id="mui-pickers-date"
        label={label}
        value={selectedDate}
        onChange={date => handleDateChange(date)}
        onBlur={e => setFieldValue(field.name, moment(new Date(e.target.value)))}
        onAccept={date => setFieldValue(field.name, date)}
        helperText={error}
        inputVariant="filled"
        InputProps={{ disableUnderline: true }}
        disabled={disabled}
        className={className}
        KeyboardButtonProps={{
          "aria-label": "change date",
          size: "small",
        }}
        format="MM/DD/YYYY"
      />
    </div>
  )
}

DatePicker.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

DatePicker.defaultProps = {
  label: "",
  disabled: false,
  className: undefined,
}

export default DatePicker

import React from "react"

import PackageCard from "../../forms/Inputs/packageCard"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(_ => ({
  card: {
    minWidth: 275,
    background:
      "linear-gradient(0deg, rgba(238,238,238,1) 0%, rgba(218,218,218,1) 35%, rgba(255,255,255,1) 100%)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 40,
  },
  demo: {
    padding: "30px 0",
  },
  root: {
    flexGrow: 1,
    paddingTop: "3%",
    paddingBottom: "3%",
  },
}))

export default function Packages({ page, onBack, onNext }) {
  const classes = useStyles()
  return (
    <Grid
      container
      className={classes.root}
      direction="row"
      justify="center"
      alignItems="center"
      spacing={5}
    >
      {page.taskIds.map(card => (
        // <RenderInput key={input.id} input={input} />
        <PackageCard key={card.id} card={card} onNext={onNext} />
      ))}
    </Grid>
  )
}

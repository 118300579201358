import React from "react"
import { Formik, Form } from "formik"
import Box from "@material-ui/core/Box"
import { saveProduct } from "../../../state/actions"
import { useStateValue } from "../../../state"
import SaveButton from "../../partials/saveButton"
import styled from "styled-components"
import PageTitle from "../../partials/pageTitle"
import { selectInitialValues } from "../../../state/selectors"
import RatingFields from "./rating"
import { get } from 'lodash'

const EditRatingSaveButton = styled(SaveButton)`
  float: right;
`
export default function EditRating() {
  const [state, dispatch] = useStateValue()
  const product = get(state, "editProduct.jsondata")

  const initialValues = selectInitialValues(product);

  const { premium_expression, pdf_example_values } = state.editProduct

  const handleSave = values => {
    if (!state.editProduct.id) {
      console.log("ERROR: no product selected")
      return
    }

    const newState = {
      ...state.editProduct,
      premium_expression: values.premium_expression,
    }
    dispatch(saveProduct(newState))
  }

  if (!state.editProduct.id) return <div>Error: select product</div>

  return (
    <Box mb={5}>
      <PageTitle title="Product Rating" />
      <Formik
        initialValues={{
          premium_expression,
          pdf_example_values,
          ...initialValues,
        }}
        onSubmit={handleSave}
      >
        <Form>
          <RatingFields state={state} />
          <EditRatingSaveButton
            type="submit"
            // onClick={() =>
            //   dispatch(saveTheme(setThemeColor(primaryColor, secondaryColor)))
            // }
          />
        </Form>
      </Formik>
    </Box>
  )
}

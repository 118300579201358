import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"

import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import Button from "@material-ui/core/Button"

import { getError } from "../../../util/forms"
// import { maskAsInteger } from "utils/forms"

const useStyles = makeStyles(theme => ({
  root: {
    // width: "100%",
    alignItems: "flex-start",
  },
}))

const YesNo = ({ field, form, label, description, ...rest }) => {
  const { value, name } = field
  const error = getError(form, name)
  const classes = useStyles()

  const { setFieldValue } = form

  const handleClickYes = () => setFieldValue(name, true)
  const handleClickNo = () => setFieldValue(name, false)

  return (
    <FormControl
      classes={{
        root: classes.root,
      }}
      required
      error={!!error}
    >
      <FormGroup>
        {description && <Typography variant="body2">{description}</Typography>}
        <FormControlLabel
        classes={{
          root: classes.root,
        }}
          control={
            <div>
              <Button
                variant={`${value ? "contained" : "outlined"}`}
                color={`${value ? "primary" : ""}`}
                onClick={handleClickYes}
              >
                Yes
              </Button>
              <Button
                variant={`${value ? "outlined" : "contained"}`}
                color={`${value ? "" : "primary"}`}
                onClick={handleClickNo}
              >
                No
              </Button>
            </div>
          }
          label={label}
          labelPlacement="top"
        />
      </FormGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

YesNo.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
}

YesNo.defaultProps = {
  label: "",
}

export default YesNo

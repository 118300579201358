import React from "react"
import Button from "@material-ui/core/Button"
// import TextField from "@material-ui/core/TextField"
import TextField from "../forms/Inputs/textField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Loading from "../partials/loading"
import Select from "../forms/Inputs/select"
import { Formik, Field } from "formik"

import { useStateValue } from "../../state"
import { createProduct } from "../../state/actions"

export default function AddProductModal(props) {
  const { open, onClose } = props
  const [state, dispatch] = useStateValue()
  const { isLoading } = state

  const { paymentProcessors } = state
  const processorSource = [
    { display: "None", value: undefined },
    ...paymentProcessors.map(processor => ({
      display: processor.title,
      value: processor.id,
    })),
  ]

  const handleSubmit = async values => {
    dispatch(createProduct(values))
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      aria-labelledby="form-dialog-title"
    >
      {isLoading && <Loading />}
      {!isLoading && (
        <>
          <DialogTitle id="form-dialog-title">Create Product</DialogTitle>
          <Formik
            initialValues={{
              title: "",
              description: "",
              paymentProcessor: undefined,
            }}
            onSubmit={handleSubmit}
            render={props => (
              <form onSubmit={props.handleSubmit}>
                <DialogContent>
                  <Field
                    component={TextField}
                    name="title"
                    label="Title"
                    placeholder="e.g. Tenant's Insurance"
                  />
                  <Field
                    component={TextField}
                    name="description"
                    label="Description"
                    placeholder="Displayed in addition to the product title"
                  />
                  <Field
                    component={TextField}
                    name="slug"
                    label="Slug"
                    placeholder="eg. equipment-breakdown"
                  />
                  <Field
                    component={Select}
                    name="paymentProcessor"
                    label="Payment Processor"
                    source={processorSource}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={onClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary">
                    Add
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        </>
      )}
    </Dialog>
  )
}

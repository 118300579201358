import React from "react"
import { selectCurrentProduct } from "../../../state/selectors"
import { Formik, Form, Field } from "formik"
import Box from "@material-ui/core/Box"
import TextField from "../../forms/Inputs/textField"
import PdfMakeInputs from "./pdfMakeInputs"
import PageTitle from "../../partials/pageTitle"

export default function() {
  const { pdf_schema } = selectCurrentProduct()

  return (
    <Box mb={5}>
      <PageTitle title="Document Builder" />
      <div>
        <Formik initialValues={pdf_schema}>
          <Form>
            <Field
              component={TextField}
              label="Page Title"
              type="text"
              name="title"
              placeholder="Page Title"
            />
            <Field
              component={TextField}
              label="Insured Field Name"
              type="text"
              name="insured"
              placeholder="eg. insuredName"
            />
            <h2>Policy</h2>
            <PdfMakeInputs />
          </Form>
        </Formik>
      </div>
    </Box>
  )
}

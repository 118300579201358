import React from "react"
import PropTypes from "prop-types"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from '@material-ui/core/Grid'

import SwitchMaterial from "@material-ui/core/Switch"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import { getError } from "../../../util/forms"

// import { maskAsInteger } from "utils/forms"

const Switch = ({
  field,
  form,
  description,
  label,
  ...labelProps
}) => {
  const error = getError(form, field.name)
  const { setFieldValue } = form

  let hasOnOffLabel = false
  if (label.includes('::')) {
    hasOnOffLabel = label.split('::')
  }

  return (
    <FormControl required error={error}>
      <FormGroup>
        <div>{description}</div>
        {hasOnOffLabel ? (
          <Grid component="label" container alignItems="center" spacing={1}>
            <Grid item>{hasOnOffLabel[0]}</Grid>
            <FormControlLabel
              control={
                <SwitchMaterial
                  checked={field.value}
                  onChange={(e, v) => setFieldValue(field.name, v)}
                  value={field.value}
                  color="primary"
                />
              }
              {...labelProps}
            />
            <Grid item>{hasOnOffLabel[1]}</Grid>
          </Grid>
        ) : (
          <FormControlLabel
            control={
              <SwitchMaterial
                checked={field.value}
                onChange={(e, v) => setFieldValue(field.name, v)}
                value={field.value}
                color="primary"
              />
            }
            label={label}
            {...labelProps}
          />
        )}
      </FormGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

Switch.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
}

Switch.defaultProps = {
  label: "",
}

export default Switch

import pdfMake from 'pdfmake/build/pdfmake';
import vfsFonts from 'pdfmake/build/vfs_fonts';
import PDFJS from 'pdfjs-dist'

export default (pdfData) => {
	const {vfs} = vfsFonts.pdfMake;
  pdfMake.vfs = vfs;
  
  const content = pdfData || {content: 'Missing Content'}
	
	pdfMake.createPdf(content).getDataUrl(dataURL => renderPDF(dataURL, document.getElementById("canvas")));
}

function renderPDF(url, canvasContainer, options) {
  options = options || { scale: 1.0 };

  function renderPage(page) {
    var viewport = page.getViewport(options.scale);
    var wrapper = document.createElement("div");
    wrapper.className = "canvas-wrapper";
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    var renderContext = {
      canvasContext: ctx,
      viewport: viewport
    };

    canvas.height = viewport.height;
    canvas.width = viewport.width;
    wrapper.appendChild(canvas);
    if (canvasContainer.firstChild) canvasContainer.removeChild(canvasContainer.firstChild)
    canvasContainer.appendChild(wrapper);

    page.render(renderContext);
  }

  function renderPages(pdfDoc) {
    for (var num = 1; num <= pdfDoc.numPages; num++)
      pdfDoc.getPage(num).then(renderPage);
  }

  PDFJS.disableWorker = true;
  PDFJS.getDocument(url).then(renderPages);
}
import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import { useStateValue } from "../../../state"
import { formToInputs, selectInitialValues } from "../../../state/selectors"
import PageTitle from "../../partials/pageTitle"
import PreviewPage from "./previewPage"

const useStyles = makeStyles(theme => ({
  pageTitle: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const Preview = ({ slug }) => {
  const [state] = useStateValue()
  const productData = slug
    ? state.shop.products.find(product => product.slug === slug)
    : state.editProduct
  const content = formToInputs(productData)

  const initialValues = selectInitialValues(productData)
  const [currentPage, setCurrentPage] = useState(0)
  const [fullScreen, setFullScreen] = useState(false)
  const classes = useStyles()

  const handleNext = () =>
    setCurrentPage(Math.min(content.length - 1, currentPage + 1))
  const handleBack = () => setCurrentPage(Math.max(0, currentPage - 1))

  function handleOpenPreview() {
    setFullScreen(true)
  }

  function handleClosePreview() {
    setFullScreen(false)
  }

  if (!content) return <div>Select a product</div>

  if (process.env.LIVE_MODE) {
    return (
      <PreviewPage
        page={content[currentPage]}
        onNext={handleNext}
        onBack={handleBack}
        initialValues={initialValues}
        pageIndex={currentPage}
        title={content[currentPage].title}
        product={productData}
      />
    )
  }

  return (
    <Box mb={5}>
      <PageTitle
        title={content[currentPage].title}
        className={classes.pageTitle}
      >
        <Button onClick={handleOpenPreview}>Fullscreen</Button>
      </PageTitle>

      <Dialog fullScreen open={fullScreen} onClose={handleClosePreview}>
        <PreviewPage
          onClose={handleClosePreview}
          page={content[currentPage]}
          onNext={handleNext}
          onBack={handleBack}
          initialValues={initialValues}
          pageIndex={currentPage}
          title={content[currentPage].title}
          product={productData}
        />
      </Dialog>

      <PreviewPage
        page={content[currentPage]}
        onNext={handleNext}
        onBack={handleBack}
        product={productData}
      />
    </Box>
  )
}

export default Preview

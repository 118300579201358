import React from "react"
import { Field, connect } from "formik"
import TextField from "../Inputs/textField"
import Switch from "../Inputs/switch"

const RequiredFields = () => (
  <>
    <Field
      component={Switch}
      name="validations.required.value"
      label="Required"
      labelPlacement="start"
    />
    <Field
      component={TextField}
      name="validations.required.message"
      label="Required Error Message"
      labelPlacement="start"
    />
  </>
)

const MinMaxFields = () => (
  <>
    <Field
      component={TextField}
      name="validations.min.value"
      type="number"
      label="Minimum"
    />
    <Field
      component={TextField}
      name="validations.min.message"
      label="Min Error Message"
      labelPlacement="start"
    />
    <Field
      component={TextField}
      name="validations.max.value"
      type="number"
      label="Maximum"
    />
    <Field
      component={TextField}
      name="validations.max.message"
      label="Max Error Message"
      labelPlacement="start"
    />
  </>
)

function ValidationEdit({ formik }) {
  const { values } = formik

  switch (values.type) {
    case "package":
      return <div>No Validations for this type.</div>
    case "checkbox":
    case "switch":
    case "text":
    case "textField":
      return <RequiredFields />
    case "number":
      return (
        <>
          <RequiredFields />
          <MinMaxFields />
        </>
      )
    case "date":
      return (
        <>
          <RequiredFields />
          <Field
            component={TextField}
            name="validations.startDateOffset.value"
            type="number"
            label="Start Date Offset"
          />
          <Field
            component={TextField}
            name="validations.startDateOffset.message"
            label="Date Offset Error Message"
            labelPlacement="start"
          />
        </>
      )
    case "radioButton":
    case "yesNo":
    case "dropdown":
      return <RequiredFields />
    case "fieldArray":
      return (
        <>
          <RequiredFields />
          <MinMaxFields />
        </>
      )
    default:
      return <div>No Attributes for this Type</div>
  }
}

export default connect(ValidationEdit)

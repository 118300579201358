import React from "react"
import Button from "@material-ui/core/Button"
import { Formik, Form, Field } from "formik"
import { makeStyles } from "@material-ui/core/styles"
import { useStateValue } from "../../../state"
import SaveButton from "../../partials/saveButton"
import TextField from "../Inputs/textField"
import Select from "../Inputs/select"

const useStyles = makeStyles(theme => ({
  buttonDiv: {
    textAlign: "right",
  },
}))

const EditColumn = ({ column }) => {
  const [, dispatch] = useStateValue()

  const handleSubmit = value => {
    dispatch({
      type: "updateColumn",
      column: value,
    })
    dispatch({
      type: "closeModal",
    })
  }

  const handleDelete = () => {
    dispatch({
      type: "deleteColumn",
      columnId: column.id,
    })
    dispatch({
      type: "closeModal",
    })
  }

  const handleClose = () =>
    dispatch({
      type: "closeModal",
    })

  // TODO: change to data-driven list from back end
  const templateTypes = [
    { display: "Package", value: "packages" },
    { display: "Configure", value: "configure" },
    { display: "Question", value: "question" },
    { display: "Terms", value: "terms" },
    { display: "Confirm", value: "confirm" },
    { display: "Checkout", value: "checkout" },
  ]

  const classes = useStyles()

  return (
    <>
      <h1>{`Edit ${column.title}`}</h1>
      <Formik
        initialValues={{
          name: column.id,
          title: column.title,
          template: column.template,
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          {/* <Field type="text" name="name" placeholder="Column Name" /> */}
          <Field
            component={TextField}
            label="Page Title"
            type="text"
            name="title"
            placeholder="Page Title"
          />
          <Field
            component={Select}
            label="Template"
            name="template"
            source={templateTypes}
          />
          <div className={classes.buttonDiv}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleDelete}
              style={{ color: "firebrick", borderColor: "firebrick" }}
            >
              Delete
            </Button>
            <SaveButton color="primary" variant="contained" type="submit">
              Save
            </SaveButton>
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default EditColumn

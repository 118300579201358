import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"

import Button from "@material-ui/core/Button"

import Paper from "@material-ui/core/Paper"
import Markdown from "../../../partials/markdown"
import { Field } from "formik"

import Checkbox from "../../Inputs/checkbox"

import terms from "./verbiage"

const useStyles = makeStyles(_ => ({
  paper: {
    padding: "2em",
  },
}))

const Terms = ({ onNext, onBack }) => {
  const classes = useStyles()
  return (
    <Paper className={classes.paper}>
      <Markdown content={terms} />
        <Field
          component={Checkbox}
          name="agreedTerms"
          label="I Have Read and Agree to the above Terms & Conditions"
        />
      <Button onClick={onNext}>Next</Button>
    </Paper>
  )
}

Terms.propTypes = {
  next: PropTypes.string.isRequired,
}

export default Terms

// TODO: merge with packageCard
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"

import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import styled from "styled-components"
import { useTheme } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  card: {
    minHeight: 275,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    border: `3px solid ${theme.palette.primary.main}`,
  },
  content: {
    textAlign: "center",
  },
}))

const StyledCard = styled(Card)`
  min-height: 275px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: ${props =>
    props.isSelected && props.theme
      ? `3px solid ${props.theme.palette.primary.main}`
      : "3px solid transparent"};
`

function checkoutCard({
  premium,
  convenienceFee,
  total,
  isSelected,
  isMonthly,
  title,
  onClick,
}) {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <>
      <Grid item xs={4} md={4} lg={4} xl={4}>
        <StyledCard isSelected={isSelected} theme={theme}>
          <CardContent className={classes.content}>
            <Typography variant="h6">
              {isMonthly ? "Monthly" : "Annual"}
            </Typography>
            <Typography variant="h2" color="primary">
              {isMonthly
                ? total.divide(12).toFormat("$0,0.00")
                : total.toFormat("$0,0.00")}
            </Typography>
            <Typography variant="subtitle2">
              {isMonthly ? "Per Month" : "Per Year"}
            </Typography>
            {isMonthly && (
              <Typography variant="caption">{`Yearly: ${total.toFormat(
                "$0,0.00"
              )}`}</Typography>
            )}
          </CardContent>
          <CardActions>
            <Button
              variant={isSelected ? "outlined" : "contained"}
              color="primary"
              onClick={onClick}
              disabled={isSelected}
            >
              {isSelected ? "Selected" : "Select Payment Option"}
            </Button>
          </CardActions>
        </StyledCard>
      </Grid>
    </>
  )
}

export default checkoutCard

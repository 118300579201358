import React from "react"
// import RenderInput from "../../forms/Inputs/renderInput"
import { templates } from "../../forms/Templates"

function RenderPage(props) {
  const { template } = props.page
  const SelectedTemplate = templates[template] || templates["question"]
  return (
    // Select the right template and pass in page props
    <>
      <SelectedTemplate {...props} />
    </>
  )
}

export default RenderPage

import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import moment from "moment"
import Grid from "@material-ui/core/Grid"
import Dinero from "dinero.js"
import Divider from "@material-ui/core/Divider"

import queryString from "query-string"

const Body = styled.div`
  margin: 0;
  padding: 0;
  width: 0;
  background-color: #fafafa;
  font: 12pt "Tahoma";
  box-sizing: border-box;
  -moz-box-sizing: border-box;
`

const Page = styled.div`
  width: 21cm;
  min-height: calc(30.7cm - ${props => props.margin}cm);
  padding: ${props => props.margin}cm;
  background: white;

  @media print {
    width: 210mm;
    height: 297mm;
    page-break-after: always;
  }
`

const Subpage = styled.div`
  // border: 2px red solid;
  height: calc(29.6cm - 2 * ${props => props.margin}cm);
  outline: ${props => props.margin}cm #ffeaea transparent;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  // border: 1px green solid;
  height: 10%;
  padding: 0.2cm;
  justify-content: space-between;
  display: flex;
  align-items: center;
  background-color: darkslategrey;
`

const HeaderText = styled.div`
  color: white;
  font-weight: bold;
  font-family: sans-serif;
`

const Footer = styled.div`
  // border: 1px green solid;
  height: 10%;
  padding: 0.2cm;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Content = styled.div`
  // border: 1px blue solid;
  height: 80%;
  padding: 0.2cm;
`

function PolicyLabel({ label, value, ...rest }) {
  return (
    <Grid container {...rest}>
      <Grid item xs={4}>
        <Typography variant="body2">
          <Box fontWeight="fontWeightBold">{label}</Box>
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="body2">{value}</Typography>
      </Grid>
    </Grid>
  )
}

function InsuredHole({ hole, yards, prizeValue, ...rest }) {
  return (
    <Grid container {...rest}>
      <Grid item xs={4}>
        <Typography variant="body2">
          <Box fontWeight="fontWeightBold">{`#${hole}`}</Box>
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">{yards}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2">
          {Dinero({ amount: Number(prizeValue) })
            .multiply(100)
            .toFormat("$0,0")}
        </Typography>
      </Grid>
    </Grid>
  )
}

function PolicyLabelContainer({ children, ...rest }) {
  return (
    <Grid container {...rest} style={{ marginTop: "10px" }}>
      {children}
    </Grid>
  )
}

const StyledPolicyLabel = styled(PolicyLabel)`
  border: ${props =>
    props.outlined ? "1px solid black" : "1px solid transparent"};
  padding-left: 1em;
`

const StyledInsuredHole = styled(InsuredHole)`
  border: ${props =>
    props.outlined ? "1px solid black" : "1px solid transparent"};
  padding-left: 1em;
`

function addressBlockToLine(block) {
  const { line1, line2, city, country, postal_code, state } = block
  return `${line2 &&
    `${line2}-`}${line1}, ${city} ${state}, ${country}, ${postal_code}`
}

function GenPolicy({ location: { search } }) {
  const { formdata, margin = 1 } = queryString.parse(search)
  const pageMargin = margin

  const values = JSON.parse(formdata)

  const {
    insuredName,
    orderNumber,
    // insurers, // TODO: should be driven by product data
    contactBlock,
    tournamentName,
    tournamentDate,
    tournamentAddress,
    amateurs,
    holes,
    prizeValue,
  } = values

  const effectiveDates = [
    moment(tournamentDate).format("MMM Do, YYYY"),
    moment(tournamentDate)
      .add(1, "years")
      .format("MMM Do, YYYY"),
  ].join(" - ")

  const insuredInfo = [
    [{ label: "Insured", value: insuredName, outlined: true }],
    [
      { label: "Policy No.", value: orderNumber },
      { label: "Insurers", value: "Intact Insurance Company of Canada" },
    ],
    [
      { label: "Mailing Address", value: addressBlockToLine(contactBlock) },
      { label: "Policy Period", value: effectiveDates },
    ],
  ]

  const tournamentInfo = [
    [
      { label: "Tournament Name", value: tournamentName },
      {
        label: "Tournament Address",
        value: addressBlockToLine(tournamentAddress),
      },
      { label: "Amateur Players", value: amateurs },
    ],
  ]

  return (
    <Body>
      <Page margin={pageMargin}>
        <Subpage margin={pageMargin}>
          <Header>
            <HeaderText>[HOLE IN ONE INSURANCE]</HeaderText>
            <img
              style={{ maxHeight: "100%" }}
              src="https://excelriskbc.ca/wp-content/uploads/2017/02/excel-risk-bc-white-logo.png"
              scale="0"
              alt=""
            ></img>
          </Header>
          <Content>
            <Typography variant="h5">Insurance Cover Note</Typography>
            <Typography variant="subtitle">May 17, 2018</Typography>
            {insuredInfo.map(entry => (
              <PolicyLabelContainer>
                {entry.map(label => (
                  <StyledPolicyLabel {...label} />
                ))}
              </PolicyLabelContainer>
            ))}

            <Typography variant="h5">Tournament</Typography>
            {tournamentInfo.map(entry => (
              <PolicyLabelContainer>
                {entry.map(label => (
                  <StyledPolicyLabel {...label} />
                ))}
              </PolicyLabelContainer>
            ))}

            <Grid container style={{ paddingTop: "1em" }}>
              <Grid item xs={4}>
                <Box fontWeight="fontWeightBold">Hole Number</Box>
              </Grid>
              <Grid item xs={4}>
                <Box fontWeight="fontWeightBold">Yards</Box>
              </Grid>
              <Grid item xs={4}>
                <Box fontWeight="fontWeightBold">Prize Value</Box>
              </Grid>

              {holes.map(hole => (
                <StyledInsuredHole {...hole} prizeValue={prizeValue} />
              ))}
            </Grid>
            <Divider style={{ marginTop: "1em" }} />
            <Typography variant="body2" style={{ marginTop: "1em" }}>
              This Cover Note is issued in accordance with the Insurer's
              standard policy wordings usual to this class of risk issued
              through Excel Insurane Brokers (Metro Vancouver) Inc. and will
              automatically terminate on the issuance of insurane policies
              covering the above risks. All terms, conditions and reference used
              in this Cover Note will be in effect as if the policy had been
              issued on the effective date of this Cover Note.
            </Typography>
            <Typography variant="body1" style={{ marginTop: "1em" }}>
              <Box fontWeight="fontWeightBold" fontSize="h7.fontSize">THIS POLICY CONTAINS A CLAUSE WHICH MAY LIMIT THE AMOUNT PAYABLE.</Box>
            </Typography>
            <Typography variant="body2" style={{ marginTop: "1em" }}>
              Excel Insurance Brokers (Metro Vancouver) Inc.
            </Typography>
            <Typography variant="body2" style={{ marginTop: "4em" }}>
              <div>per</div>
              <div style={{ paddingLeft: "1em" }}>
                Ricardo de Guzman, FCIP CRM MIBA
              </div>
              <div style={{ paddingLeft: "1em" }}>President</div>
            </Typography>
          </Content>
          <Footer>
            <Typography variant="body2"><Box fontWeight="fontWeightBold">Excel Insurance Brokers (Metro Vancouver) Inc.</Box></Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="body2">320-1111 Melville Street, Vancouver, BC V6E 3V6</Typography>
              <Typography variant="body2">TF 1.877.862.7258</Typography>
              <Typography variant="body2">T 604.282.7719</Typography>
              <Typography variant="body2">F 604.282.7762</Typography>
            </div>
          </Footer>
        </Subpage>
      </Page>
    </Body>
  )
}

GenPolicy.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }),
}

GenPolicy.defaultProps = {
  location: {
    search: "",
  },
}

export default GenPolicy

import React from "react"
import Card from "@material-ui/core/Card"
import DragIcon from "@material-ui/icons/DragIndicator"
import EditIcon from "@material-ui/icons/Edit"
import Typography from "@material-ui/core/Typography"
import styled from "styled-components"
import { Draggable, Droppable } from "react-beautiful-dnd"

import { useStateValue } from "../../../state"

import IconButton from "../../partials/iconButton"
import EditTask from "../../forms/EditTask"

const TaskContainer = styled(({ isDragging, ...other }) => <Card {...other} />)`
  border-radius: 2px;
  transition: background-color 0.2s ease;
  background-color: ${props => (props.isDragging ? "lightgreen" : "white")};
  margin-bottom: 8px;
`

const Container = styled.div`
  display: flex;
`

const TypeLabel = styled(Typography)`
  padding-left: 8px;
`

const Handle = styled.div`
  border-right: 1px solid lightgrey;
  min-width: 25px;
  display: flex;
  align-items: center;
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const FieldArrayTasks = styled.div`
  padding: 8px 8px 8px 32px;
  background-color: ${props => (props.isDraggingOver ? "skyblue" : "inherit")};
  transition: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 100px;
`

const Task = ({ task, index }) => {
  const [, dispatch] = useStateValue()
  const handleEdit = () => {
    return dispatch({
      type: "openModal",
      content: <EditTask task={task} key={task.id} />,
    })
  }

  function handleAddFieldArrayTask() {
    dispatch({
      type: "ADD_FIELD_ARRAY_TASK",
      taskId: task.id,
    })
  }

  return (
    <Draggable draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <TaskContainer
          {...provided.draggableProps}
          innerRef={provided.innerRef}
          isDragging={snapshot.isDragging}
        >
          <Container>
            <Handle {...provided.dragHandleProps}>
              <DragIcon />
            </Handle>
            <Content>
              <div>
                <TypeLabel variant="body1" noWrap>
                  {task.label}
                </TypeLabel>
                <TypeLabel variant="body2" color="primary" noWrap>
                  {task.type}
                </TypeLabel>
              </div>
              <IconButton onClick={handleEdit}>
                <EditIcon color="action" />
              </IconButton>
            </Content>
          </Container>
          {task.type === "fieldArray" && (
            <Droppable droppableId={task.id} type={`fieldArray-${task.id}`}>
              {(provided, snapshot) => (
                <FieldArrayTasks
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  isDraggingOver={snapshot.isDraggingOver}
                >
                  {task.taskIds &&
                    task.taskIds.map((subtask, index) => (
                      // <div key={subtask.id}>{subtask.id}</div>
                      <Task key={subtask.id} task={subtask} index={index} />
                    ))}
                  {provided.placeholder}
                  <button onClick={handleAddFieldArrayTask}>+</button>
                </FieldArrayTasks>
              )}
            </Droppable>
          )}
        </TaskContainer>
      )}
    </Draggable>
  )
}

export default Task

import axios from "axios"

export default {
  async getDocDefinition(productId, pdf_schema) {
    try {
      const response = await axios.post(
        `${process.env.PRODUCT_API_URL}/preview/${productId}`,
        { pdf_schema },
        { withCredentials: true }
      )
      return response.data.docDefinition
    } catch(error) {
      return error
    }
  },
}

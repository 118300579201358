import React, { useState } from "react"
import ReactModal from "react-modal"
import MaterialModal from "@material-ui/core/Modal"
import { useStateValue } from "../../state"
import { makeStyles } from "@material-ui/core/styles"

ReactModal.setAppElement(`#___gatsby`)

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4),
    outline: "none",
  },
}))

const Modal = props => {
  const [state, dispatch] = useStateValue()
  const [modalStyle] = useState(getModalStyle)
  const classes = useStyles()

  const handleClick = () =>
    dispatch({
      type: "closeModal",
    })

  return (
    <MaterialModal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={state.isModalOpen}
      onClose={handleClick}
    >
      <div style={modalStyle} className={classes.paper}>
        {state.modalContent}
      </div>
    </MaterialModal>
  )
}

export default Modal

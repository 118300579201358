import React, { useState } from "react"
import PropTypes from "prop-types"
import TextFieldMaterial from "@material-ui/core/TextField"

import { getError } from "../../../util/forms"

// import { maskAsInteger } from "utils/forms"

const ArrayTextField = ({
  field,
  form,
  label,
  className,
  disabled,
  placeholder,
  style,
  description,
  ...rest
}) => {
  const error = getError(form, field.name)
  const { onChange, onBlur, value, ...restField } = field
  const { setFieldValue } = form
  const [displayValue, setDisplayValue] = useState(value)

  return (
    // <div>
    //   {description && <div>{description}</div>}
    <TextFieldMaterial
      error={!!error}
      label={label}
      onChange={(e) => {
        const { value: targetValue } = e.target
        setDisplayValue(targetValue)
      }}
      onBlur={e => {
        const { value: targetValue } = e.target
        const result = targetValue.split(',').map(s => s.trim())
        setDisplayValue(result.join(','))
        setFieldValue(field.name, result)
      }}
      value={displayValue}
      {...restField}
      helperText={error}
      disabled={disabled}
      className={className}
      placeholder={placeholder}
      {...rest}
    />
    // </div>
  )
}

ArrayTextField.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

ArrayTextField.defaultProps = {
  label: "",
  disabled: false,
  className: undefined,
}

export default ArrayTextField

import React from "react"
import IconButton from "@material-ui/core/IconButton"
import DeleteIcon from "@material-ui/icons/Delete"

export default function DeleteIconButton(props) {
  return (
    <div>
      <IconButton
        type="button"
        size="small"
        style={{ color: "firebrick" }}
        aria-label="Delete"
        {...props}
      >
        <DeleteIcon />
      </IconButton>
    </div>
  )
}

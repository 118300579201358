import React from "react"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"

function SuccessPage(props) {
  const {
    // amount,
    // currency,
    // receipt_email,
    // receipt_number,
    order_number = "00000000000000",
    // receipt_url,
  } = props.location.state

  const orderNumberDisplay = [
    order_number.slice(0, 4),
    order_number.slice(4, 10),
    order_number.slice(10, 14)
  ].join('-')

  return (
    <Container maxWidth="md">
      <Typography variant="h2" align="center">Thank you.</Typography>
      <Typography variant="h4" align="center">Your order was completed successfully.</Typography>
      <Typography variant="body1" align="center">An email containing the details of your order has been sent to the address provided.</Typography>
      <Typography variant="body1" align="center">{`Order#: ${orderNumberDisplay}`}</Typography>
    </Container>
  )
}

export default SuccessPage

import React from "react"
import { Formik, Form } from "formik"
import RenderPage from "./renderPage"
import Container from "@material-ui/core/Container"
import Header from "./header"
import * as Yup from "yup"
import { keyBy, mapValues } from "lodash"
import moment from "moment"

const constructYupValidation = task => {
  const { type, validations, taskIds } = task

  switch (type) {
    case "fieldArray": {
      const fields = keyBy(taskIds, "name")
      const fieldsValidations = mapValues(fields, constructYupValidation)

      const fieldsSchema = Yup.object().shape(fieldsValidations)
      const result = createValidation(Yup.array(), validations).of(fieldsSchema)

      return result
    }
    case "number": {
      return createValidation(Yup.number(), validations)
    }
    case "date":
    case "text":
    case "textField":
      return createValidation(Yup.string(), validations)
    case "addressBlock":
      const postalCodeRegex = new RegExp(/^[A-Z]\d[A-Z]\d[A-Z]\d$/);
      return Yup.object().shape({
        line1: Yup.string().required("Address required"),
        line2: Yup.string(),
        country: Yup.string().required("Country required"),
        city: Yup.string().required("City required"),
        state: Yup.string().required("State required"),
        postal_code: Yup.string().matches(postalCodeRegex, { message: "Postal code must in format V1V1V1" })
        .required("Please enter your postal code")
      })
      
    default:
      return undefined
  }
}

const createValidation = (base, validations) => {
  if (!validations) return base

  const validationPairs = Object.keys(validations)
    .map(key => ({
      type: key,
      value: validations[key].value,
      message: validations[key].message,
    }))
    .filter(pair => pair.value)

  return applyValidations(base, validationPairs)
}

// recursively create yup validations from schema
const applyValidations = (base, pairs) => {
  if (pairs.length === 0) return base

  const newPairs = [...pairs]
  const validation = newPairs.pop()

  const { type, value, message } = validation

  const errorMessage =
    typeof message === "string" && message.length <= 0 ? undefined : message

  switch (type) {
    case "required":
      base = base.required(errorMessage)
      break
    case "min":
      base = base.min(value, errorMessage)
      break
    case "max":
      base = base.max(value, errorMessage)
      break
    case "startDateOffset":
      base = base.test(
        "",
        `Date must be at least ${value} day later.`,
        value => {
          const result = moment()
            .startOf("day")
            .diff(moment(value), "days")
          return result < 0
        }
      )
      break
    default:
      break
  }

  return applyValidations(base, newPairs)
}

const generateValidations = page => {
  // filter out tasks that do not have validations
  // const questionsWithValidations = page.filter(question => question.validations || (question.taskIds))
  const { taskIds } = page
  const questionsWithValidations = taskIds.filter(
    question =>
      question.validations ||
      question.taskIds ||
      question.type === "addressBlock"
  )

  if (questionsWithValidations.length === 0) return

  const keyed = keyBy(taskIds, "name")
  const yupResult = mapValues(keyed, constructYupValidation)

  return Yup.object().shape(yupResult)
}

function PreviewPage(props) {
  const validationSchema = generateValidations(props.page)

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        onSubmit={ev => {
          return props.onNext()
        }}
        validationSchema={validationSchema}
      >
        <Form>
          <Header
            onClose={props.onClose}
            onNext={props.onNext}
            onBack={props.onBack}
            pageIndex={props.pageIndex}
            title={props.title}
            product={props.product}
          />
          <Container maxWidth="lg" style={{ marginTop: "2em", marginBottom: "2em" }}>
            <RenderPage {...props} />
          </Container>
        </Form>
      </Formik>
    </>
  )
}

export default PreviewPage

import React from "react"
import { FieldArray as FieldArrayFormik, connect } from "formik"
import Typography from "@material-ui/core/Typography"
import RenderInput from "./renderInput"
import Button from "@material-ui/core/Button"
import FormGroup from "@material-ui/core/FormGroup"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import { getDefaultValue } from "../../../state/selectors"
import { keyBy, mapValues } from "lodash"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
}))

function FieldArray({ input, formik }) {
  const classes = useStyles()
  const { values, errors } = formik
  const { name, addSingle, addMultiple, taskIds, description, label } = input
  const blankField = mapValues(keyBy(taskIds, "name"), getDefaultValue)
  const arrayInsert = (helper, index) => () =>
    helper.insert(index + 1, blankField)

  return (
    <Grid container item xs={12}>
      <FormControl required error={!!errors[name]}>
        <FormLabel><Typography variant="h6" display="inline">{label}</Typography></FormLabel>
        <Typography variant="body2">{description}</Typography>
        <FormGroup style={{ paddingTop: "10px" }}>
          <FieldArrayFormik
            name={`${name}`}
            render={arrayHelpers => (
              <>
                <Grid container spacing={2}>
                  {values[name] && values[name].length > 0 ? (
                    values[name].map((_, index) => (
                      <Grid item xs={12} key={index}>
                        <Paper className={classes.paper}>
                          <Grid
                            item
                            container
                            key={`${name}.${index}`}
                            spacing={2}
                          >
                            {input.taskIds.map(item => {
                              const newInput = { ...item }
                              newInput.name = `${name}.${index}.${newInput.name}`
                              return (
                                <RenderInput
                                  input={newInput}
                                  key={newInput.name}
                                />
                              )
                            })}
                            <Grid item xs={12}>
                              <Button
                                type="button"
                                onClick={arrayInsert(arrayHelpers, index)}
                                variant="outlined"
                              >
                                {/* show this when user has removed all friends from the list */}
                                {addMultiple || `Add Another ${label}`}
                              </Button>
                              <Button
                                type="button"
                                onClick={() => arrayHelpers.remove(index)}
                                variant="outlined"
                              >
                                {/* show this when user has removed all friends from the list */}
                                {`Remove ${label}`}
                              </Button>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Button
                        type="button"
                        onClick={() => arrayHelpers.push(blankField)}
                        variant="outlined"
                      >
                        {/* show this when user has removed all friends from the list */}
                        {addSingle || `Add ${label}`}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
          />
        </FormGroup>
        {errors[name] && typeof errors[name] === "string" && (
          <FormHelperText>{errors[name]}</FormHelperText>
        )}
      </FormControl>
    </Grid>
  )
}

export default connect(FieldArray)

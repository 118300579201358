import React from "react"
import { Formik, Form, Field } from "formik"
import { useStateValue } from "../../../state"
import TextField from "../Inputs/textField"
import Select from "../Inputs/select"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import InputAttributes from "./inputAttributes"
import ValidationEdit from "./validationEdit"
import SaveButton from "../../partials/saveButton"
import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  buttonDiv: {
    textAlign: "right",
  },
}))

const EditTask = ({ task }) => {
  const [, dispatch] = useStateValue()
  const {
    id,
    name,
    label,
    type,
    price,
    sellingPoints,
    preset,
    options,
    validations,
    fieldWidth = "large",
    affectsPremium = false,
    description,
    dependencies,
  } = task
  const classes = useStyles()

  const handleSubmit = value => {
    dispatch({
      type: "updateTask",
      task: value,
    })
    dispatch({
      type: "closeModal",
    })
  }

  // TODO: move to actions.js
  const handleDelete = () => {
    dispatch(dispatch => {
      dispatch({
        type: "deleteTask",
        task: task.id,
      })
      dispatch({
        type: "closeModal",
      })
    })
  }

  const handleClose = () =>
    dispatch({
      type: "closeModal",
    })

  const inputTypes = [
    { display: "Text", value: "text" },
    { display: "Text Field", value: "textField" },
    { display: "Checkbox", value: "checkbox" },
    { display: "Dropdown", value: "dropdown" },
    { display: "Date", value: "date" },
    { display: "Number", value: "number" },
    { display: "Field Array", value: "fieldArray" },
    { display: "Address Block", value: "addressBlock" },
    { display: "Radio Button", value: "radioButton" },
    { display: "Yes/No Buttons", value: "yesNo" },
    { display: "Switch", value: "switch" },
    { display: "Package*", value: "package" },
  ]

  const sizeTypes = [
    { display: "Large", value: "large" },
    { display: "Medium", value: "medium" },
    { display: "Small", value: "small" },
  ]

  const operatorTypes = [
    { display: "Equals", value: "equals" },
    { display: "Not Equals", value: "not" },
    { display: "Minimum", value: "min" },
    { display: "Maximum", value: "max" },
  ]

  return (
    <>
      <h1>{`Edit ${label}`}</h1>
      <Formik
        initialValues={{
          id,
          name,
          label,
          type,
          price,
          sellingPoints,
          preset,
          options,
          validations,
          fieldWidth,
          affectsPremium,
          description,
          dependencies,
        }}
        onSubmit={handleSubmit}
      >
        <Form>
          <Typography variant="h6">Field</Typography>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Field
              component={TextField}
              name="label"
              label="Label"
              placeholder="Input Title"
            />
            <Field
              label="Type"
              component={Select}
              name="type"
              source={inputTypes}
            />
            <Field
              label="Field Width"
              component={Select}
              name="fieldWidth"
              source={sizeTypes}
            />
            <Field
              component={TextField}
              name="description"
              label="Description"
              placeholder="describe..."
            />
            <Field
              component={TextField}
              name="dependencies.field"
              label="Dependent Field"
              placeholder="Dependent field name..."
            />
            <Field
              component={Select}
              name="dependencies.operator"
              label="Dependent Field Operator"
              source={operatorTypes}
            />
            <Field
              component={TextField}
              name="dependencies.value"
              label="Dependent Field Value"
              placeholder="eg. 'masonry'"
            />
          </div>
          <Typography variant="h6">Input Attributes</Typography>
          <InputAttributes />
          <Typography variant="h6">Validations</Typography>
          <ValidationEdit />
          {/* {task.type === "package" && (
            <>
              <Field label="Price" component={TextField} name="price" />
            </>
          )} */}
          <div className={classes.buttonDiv}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              onClick={handleDelete}
              style={{ color: "firebrick", borderColor: "firebrick" }}
            >
              Delete
            </Button>
            <SaveButton color="primary" variant="contained" type="submit">
              Save
            </SaveButton>
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default EditTask

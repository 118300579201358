import React, { useEffect } from "react"
import PropTypes from "prop-types"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"

import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from '@material-ui/core/FormLabel';

import { getError } from "../../../util/forms"

const RadioButton = ({ source, field, form, label, description, ...rest }) => {
  const { value, name } = field
  const { setFieldValue } = form
  const error = getError(form, name)

  useEffect(() => {
    setFieldValue(name, value)
  }, [value])

  return (
    <div style={{padding: '10px 0px'}}>
      <FormControl required error={!!error}>
        <FormLabel component="legend">{label}</FormLabel>
          <FormGroup>
            <div>{description}</div>
            <RadioGroup
              aria-label="choice"
              name="Choice"
              value={value}
              onChange={(e) => {
                setFieldValue(name, e.target.value);
              }}
              {...rest}
            >
              {source.map((choice, index) => (
                <FormControlLabel
                  key={index}
                  value={choice.value}
                  control={<Radio color="primary" />}
                  label={choice.display}
                />
              ))}
            </RadioGroup>
          </FormGroup>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </div>
  )
}

RadioButton.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
}

RadioButton.defaultProps = {
  label: "",
}

export default RadioButton

import React from "react"
import PropTypes from "prop-types"

import FilledInput from "@material-ui/core/FilledInput"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import { getError } from "../../../util/forms"

import { get } from "lodash"

const SimpleSelect = ({
  source,
  label,
  field,
  allowNone,
  form,
  disabled,
  className,
  description,
  placeholder = "None",
}) => {
  const error = getError(form, field.name)

  const selectValue = get(form.values, field.name, "")

  return (
    <>
      <div>{description}</div>
      <FormControl variant="filled" error={!!error} disabled={disabled}>
        <InputLabel htmlFor="component-outlined">{label}</InputLabel>
        <Select
          {...field}
          renderValue={val =>{
            let element = source.find(option => option.value === `${val}`)
            if (!element && allowNone) return placeholder 
            return get(element, "display")
          }}
          input={<FilledInput id="component-outlined" />}
          value={selectValue}
        >
          {allowNone && (
            <MenuItem value="">
              <em>{placeholder}</em>
            </MenuItem>
          )}
          {source.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
        {error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    </>
  )
}

SimpleSelect.propTypes = {
  source: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.string, display: PropTypes.string })
  ).isRequired,
  field: PropTypes.shape({}).isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  allowNone: PropTypes.bool,
  form: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

SimpleSelect.defaultProps = {
  error: "",
  label: "",
  allowNone: false,
  disabled: false,
  className: undefined,
}

export default SimpleSelect

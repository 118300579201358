import React from "react"
import RenderInput from "../Inputs/renderInput"

import Grid from "@material-ui/core/Grid"

export default function Question({ page }) {
  return (
    <Grid container spacing={2}>
      {page.taskIds.map(input => (
        <RenderInput key={input.id} input={input} />
      ))}
    </Grid>
  )
}

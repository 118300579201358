import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from 'formik'
import Typography from "@material-ui/core/Typography"

import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import CheckIcon from "@material-ui/icons/Check"

const useStyles = makeStyles(_ => ({
  card: {
    minWidth: 275,
  },
  pos: {
    marginBottom: 40,
  },
  sellingPoint: {
    display: "flex",
  },
}))

function PackageCard({ card: { label, price, sellingPoints, type, preset }, onNext, formik }) {
  const classes = useStyles()
  // const { label, price, sellingPoints } = card

  const handleCustomize = () => {
    preset.forEach(item => {
      formik.setFieldValue(item.field, item.value)
    })
    onNext()
  }

  if (type !== "package") {
    return <></>
  }

  return (
    <Grid key={label} item xs={12} md={4}>
      <Card className={classes.card}>
        <CardContent style={{ padding: 30 }}>
          <Typography color="primary" gutterBottom variant="h4" component="h3">
            {label}
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Starting at
          </Typography>
          <Typography variant="h4" gutterBottom>
            <Typography
              style={{ display: "inline-block", marginRight: 8 }}
              variant="body1"
            >
              $
            </Typography>
            {price}
          </Typography>

          {sellingPoints.map(point => (
            <div key={point.description} className={classes.sellingPoint}>
              <CheckIcon color="primary" />
              <Typography variant="body1">{point.description}</Typography>
            </div>
          ))}
        </CardContent>
        <CardActions style={{ textAlign: "center", padding: "20px 0 40px 0" }}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            fullWidth
            onClick={handleCustomize}
          >
            View and Customize
          </Button>
        </CardActions>
      </Card>
    </Grid>
  )
}

PackageCard.propTypes = {
  card: PropTypes.shape({
    sellingPoints: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
}

PackageCard.defaultProps = {
  card: { sellingPoints: [] },
}

export default connect(PackageCard)

import React from "react"
import styled from "styled-components"
import Card from "@material-ui/core/Card"
import EditIcon from "@material-ui/icons/Edit"
import AddCircleIcon from "@material-ui/icons/Add"
import { Draggable, Droppable } from "react-beautiful-dnd"
import { useStateValue } from "../../../state"
import Task from "./task"
import IconButton from "../../partials/iconButton"
import EditColumn from "../../forms/EditColumn"

const Container = styled(Card)`
  margin-top: 8px;
  margin-bottom: 8px;
  border-radius: 6px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
`

const Title = styled.h3`
  margin-bottom: 0px;
`

const TitleContainer = styled.div`
  flex-grow: 1;
`

const TitleBar = styled.div`
  display: flex;
  padding: 0px 8px;
  align-items: center;
`

const TaskList = styled.div`
  padding: 8px;
  background-color: ${props => (props.isDraggingOver ? "skyblue" : "inherit")};
  transition: background-color 0.2s ease;
  flex-grow: 1;
  min-height: 100px;
`

const Column = ({ column, tasks, index, type }) => {
  const [, dispatch] = useStateValue()

  function handleEdit() {
    return dispatch({
      type: "openModal",
      content: <EditColumn column={column} />,
    })
  }

  function handleAdd() {
    dispatch({
      type: "addTask",
      columnId: column.id,
    })
  }

  return (
    <Draggable draggableId={column.id} index={index}>
      {provided => (
        <Container {...provided.draggableProps} ref={provided.innerRef}>
          <TitleBar>
            <TitleContainer>
              <Title {...provided.dragHandleProps}>{column.title}</Title>
              <div>{column.template}</div>
            </TitleContainer>
            {/* <EditOptions column={column} /> */}
            <IconButton onClick={handleEdit}>
              <EditIcon color="action" />
            </IconButton>
            <IconButton onClick={handleAdd}>
              <AddCircleIcon color="action" />
            </IconButton>
          </TitleBar>

          <Droppable droppableId={column.id} type={type || "task"}>
            {(provided, snapshot) => (
              <TaskList
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {tasks.map((task, index) => (
                  <Task key={task.id} task={task} index={index} />
                ))}
                {provided.placeholder}
              </TaskList>
            )}
          </Droppable>
        </Container>
      )}
    </Draggable>
  )
}

export default Column

import React from "react"
import { useStateValue } from "../../../state"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: "24px",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  cardActions: {
    justifyContent: "center",
  },
}))

function Shop() {
  const [state] = useStateValue()
  const { shop } = state
  const classes = useStyles()

  console.log(shop)

  return (
    <div className={classes.root}>
      <AppBar position='static'>
        <Toolbar>
          <Typography variant='h6' className={classes.title}>
            Brand
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth='md' className={classes.container}>
        <Grid container spacing={3}>
          {shop.products.map(product => {
            return (
              <Grid item xs={12} sm={6}  md={4}>
                <Card className={classes.card}>
                  <div>
                    <CardMedia
                      src='http://lorempixel.com/400/200/sports/1/'
                      component='img'
                    />
                    <CardContent>
                      <Typography align="center" variant='h6'>{product.title}</Typography>
                    </CardContent>
                  </div>
                  <div>
                    <CardActions disableSpacing className={classes.cardActions}>
                      <Link to={`/${product.slug}`}>
                        <Button variant='contained' color='primary'>
                          Buy Now
                        </Button>
                      </Link>
                    </CardActions>
                  </div>
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </Container>
    </div>
  )
}

export default Shop

import React from "react"
import PropTypes from "prop-types"
import Typography from '@material-ui/core/Typography'
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"

import CheckboxMaterial from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

import { getError } from "../../../util/forms"
// import { maskAsInteger } from "utils/forms"

const Checkbox = ({ field, form, label, description, ...rest }) => {
  const error = getError(form, field.name)
  const { onChange } = rest

  const { setFieldValue } = form

  return (
    <FormControl required error={!!error}>
      <FormGroup>
        {description && <Typography variant="body2">{description}</Typography>}
        <FormControlLabel
          control={
            <CheckboxMaterial
              checked={field.value}
              onChange={(e, v) => {
                if (onChange) onChange(v)
                setFieldValue(field.name, v)
              }}
              value={field.value}
              color="primary"
            />
          }
          label={label}
        />
      </FormGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

Checkbox.propTypes = {
  field: PropTypes.shape({}).isRequired,
  form: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
}

Checkbox.defaultProps = {
  label: "",
}

export default Checkbox

import React from "react"
import TextField from "../textField"
import { Field } from "formik"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import FormLabel from "@material-ui/core/FormLabel"
import Select from "../select"

const provinceSource = [
  { display: "Alberta", value: "AB" },
  { display: "British Columbia", value: "BC" },
  { display: "Manitoba", value: "MN" },
  { display: "New Brunswick", value: "NB" },
  { display: "Newfoundland", value: "NL" },
  { display: "Nova Scotia", value: "NS" },
  { display: "Northwest Territories", value: "NT" },
  { display: "Nunavut", value: "NU" },
  { display: "Ontario", value: "ON" },
  { display: "Prince Edward Island", value: "PE" },
  { display: "Quebec", value: "QC" },
  { display: "Saskatchewan", value: "SK" },
  { display: "Yukon", value: "YT" },
]

// injected component renders a component right below the description
function AddressBlock({
  name,
  label,
  description,
  injectedComponent,
  ...rest
}) {
  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <FormLabel>
          <Typography variant="h6" display="inline">
            {label}
          </Typography>
        </FormLabel>
        <Typography variant="body2">{description}</Typography>
        {injectedComponent}
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Field
            key={`${name}.line1`}
            component={TextField}
            name={`${name}.line1`}
            placeholder="Street"
            {...rest}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            key={`${name}.line2`}
            component={TextField}
            name={`${name}.line2`}
            placeholder="Apt #"
            {...rest}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            key={`${name}.city`}
            component={TextField}
            name={`${name}.city`}
            placeholder="City"
            {...rest}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            key={`${name}.state`}
            component={Select}
            name={`${name}.state`}
            placeholder="Select Province"
            allowNone
            source={provinceSource}
            {...rest}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            key={`${name}.country`}
            component={TextField}
            name={`${name}.country`}
            placeholder="Country"
            disabled={true}
            {...rest}
          />
        </Grid>
        <Grid item xs={6}>
          <Field
            key={`${name}.postal_code`}
            component={TextField}
            name={`${name}.postal_code`}
            placeholder="Postal Code"
            {...rest}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AddressBlock

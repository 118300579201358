import React from "react"
import Button from "@material-ui/core/Button"
import SaveIcon from "@material-ui/icons/Save"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}))

export default function SaveButton(props) {
  const classes = useStyles()
  return (
    <Button variant="outlined" {...props}>
      <SaveIcon className={clsx(classes.leftIcon, classes.iconSmall)} />
      Save
    </Button>
  )
}

SaveButton.defaultProps = {
  color: "primary",
  variant: "contained",
}

import React from "react"
import IconButtonMaterial from "@material-ui/core/IconButton"
import EditIcon from "@material-ui/icons/Edit"

const IconButton = ({ onClick, ...rest }) => {
  function handleClick() {
    onClick()
  }

  return (
    <IconButtonMaterial
      onClick={handleClick}
      color="primary"
      component="span"
      size="small"
    >
      {rest.children || <EditIcon />}
    </IconButtonMaterial>
  )
}

export default IconButton

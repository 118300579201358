import React from "react"
import { Field, connect } from "formik"
import TextField from "./textField"
import Select from "./select"
import DatePicker from "./datepicker"
import AddressBlock from "./AddressBlock"
import FieldArray from "./fieldArray"
import Grid from "@material-ui/core/Grid"
import Switch from "./switch"
import CheckBox from "./checkbox"
import RadioButton from "./radioButton"
import YesNo from "./yesno"
import { get } from 'lodash'

function RenderInput({ input, formik, ...rest }) {
  const { dependencies } = input
  if (dependencies && dependencies.field) {
    const { values } = formik
    let dependentValue = values[dependencies.field]

    // check if field is in a fieldArray and search it
    if (!dependentValue) {
      const fieldName = input.name.split('.')
      if (fieldName.length > 1) {
        fieldName.pop()
        fieldName.push(dependencies.field)
        dependentValue = get(values, fieldName.join('.'))
      }
    }

    let allowRender
    if (dependencies.field === "sprinklered") {
      console.log('sprinklered', values, input, dependencies.value)
    }

    let booleanValue
    if (dependencies.value === "true") {
      booleanValue = true
    } else if (dependencies.value === "false") {
      booleanValue = false
    }

    switch (dependencies.operator) {
      case "equals":
        allowRender = dependentValue === dependencies.value || dependentValue === booleanValue
        break
      case "not":
        allowRender = dependentValue !== dependencies.value|| dependentValue === booleanValue
        break
      case "min":
        allowRender = dependentValue >= dependencies.value
        break
      case "max":
        allowRender = dependentValue <= dependencies.value
        break
      default:
        break
    }

    if (!allowRender) return <></>
  }

  // select the right input type
  const {
    type,
    name,
    label,
    options = [],
    fieldWidth = "large",
    description,
  } = input

  const sizeHash = {
    small: { xs: 4 },
    medium: { xs: 6 },
    large: { xs: 12 },
  }

  switch (type) {
    case "text":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            component={TextField}
            description={description}
            name={name}
            label={label}
            {...rest}
          />
        </Grid>
      )
    case "textField":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            component={TextField}
            description={description}
            name={name}
            label={label}
            multiline
            {...rest}
          />
        </Grid>
      )
    case "number":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            component={TextField}
            description={description}
            name={name}
            label={label}
            type="number"
            {...rest}
          />
        </Grid>
      )
    case "date":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            name={name}
            component={DatePicker}
            description={description}
            label={label}
            // disabled={disabled}
            inputVariant="outlined"
            {...rest}
          />
        </Grid>
      )
    case "dropdown":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            component={Select}
            description={description}
            name={name}
            source={options}
            label={label}
            {...rest}
          />
        </Grid>
      )
    case "switch":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            component={Switch}
            description={description}
            name={name}
            label={label}
            {...rest}
          />
        </Grid>
      )
    case "checkbox":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            component={CheckBox}
            description={description}
            name={name}
            label={label}
            {...rest}
          />
        </Grid>
      )
    case "yesNo":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            key={name}
            component={YesNo}
            description={description}
            name={name}
            label={label}
            {...rest}
          />
        </Grid>
      )
    case "radioButton":
      return (
        <Grid item {...sizeHash[fieldWidth]}>
          <Field
            source={options}
            key={name}
            component={RadioButton}
            description={description}
            name={name}
            label={label}
            {...rest}
          />
        </Grid>
      )
    case "addressBlock":
      return (
        <AddressBlock
          name={name}
          label={label}
          description={description}
          {...rest}
        />
      )
    case "fieldArray":
      return <FieldArray input={input} description={description} {...rest} />
    default:
      return <div>{input.label}</div>
  }
}

export default connect(RenderInput)

// This component must be placed inside the Formik
// provider, which contains all values relevant
// to the premium.

import React from "react"
import clsx from "clsx"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Typography } from "@material-ui/core"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { connect } from "formik"
import { selectPremiumBreakdown } from "../../state/selectors"

const TotalPrice = styled(Typography)`
  margin: 10px;
  padding: 10px;
  border: 1px solid grey;
  background: #fff;

  const useStyles = makeStyles(theme => ({
    cellRoot: {
      paddingLeft: 28,
    },
    cellTotal: {
      fontWeight: 500,
    },
    grid: {
      minHeight: "100vh",
    },
    container: {
      padding: 20,
    },
  }))
  
`

const useStyles = makeStyles(theme => ({
  cellRoot: {
    paddingLeft: 28,
  },
  cellTotal: {
    fontWeight: 500,
  },
  grid: {
    minHeight: "100vh",
  },
  container: {
    padding: 20,
  },
}))

function PremiumBreakdown({
  formik,
  showBreakdown = false,
  showFinal = false,
  product,
}) {
  const { values } = formik
  const classes = useStyles()
  const premium = selectPremiumBreakdown(values, product)

  return (
    <>
      <Typography align='center' variant='h4'>
        {showFinal ? "Premium" : "Estimated Total"}
      </Typography>
      <TotalPrice align='center' variant='h4'>
        {showBreakdown
          ? premium.total.toFormat("$0,0.00")
          : premium.premium.toFormat("$0,0.00")}
      </TotalPrice>
      {showBreakdown && (
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='right'>Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Policy Coverage</TableCell>
              <TableCell align='right'>
                {premium.premium.toFormat("$0,0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                classes={{
                  root: classes.cellRoot,
                }}
              >
                Processing Fee
              </TableCell>
              <TableCell align='right'>
                {premium.convenienceFee.toFormat("$0,0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={clsx(classes.cellRoot, classes.cellTotal)}>
                Total
              </TableCell>
              <TableCell
                classes={{
                  root: classes.cellTotal,
                }}
                align='right'
              >
                {premium.total.toFormat("$0,0.00")}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </>
  )
}

export default connect(PremiumBreakdown)

import React from "react"
import PropTypes from "prop-types"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"

const PageTitle = ({ title, children, className }) => {
  return (
    <Box mb={3} className={className}>
      <Typography variant="h3">{title}</Typography>
      {children}
    </Box>
  )
}

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
}

export default PageTitle

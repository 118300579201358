import React from "react"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { Field, FieldArray, connect } from "formik"
import TextField from "../Inputs/textField"
import Checkbox from "../Inputs/checkbox"
import DeleteIconButton from "../../partials/deleteIconButton"

function InputAttributes({ formik }) {
  const { values } = formik

  const arrayInsert = (helper, index) => () => helper.insert(index + 1, "")

  switch (values.type) {
    case "package":
      return (
        <>
          <Field label="Price" component={TextField} name="price" />
          <Typography variant="subtitle2">Selling Points</Typography>
          <FieldArray
            name="sellingPoints"
            render={arrayHelpers => (
              <div>
                {values.sellingPoints && values.sellingPoints.length > 0 ? (
                  values.sellingPoints.map((_, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Field
                        style={{ flexGrow: 1 }}
                        component={TextField}
                        label={`Point#${index}`}
                        name={`sellingPoints.${index}.description`}
                      />
                      <DeleteIconButton
                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                      />
                      <Button
                        type="button"
                        onClick={arrayInsert(arrayHelpers, index)} // insert an empty string at a position
                      >
                        +
                      </Button>
                    </div>
                  ))
                ) : (
                  <button
                    type="button"
                    onClick={() => arrayHelpers.push({ description: "" })}
                  >
                    {/* show this when user has removed all friends from the list */}
                    Add Selling Points
                  </button>
                )}
              </div>
            )}
          />
          <Typography variant="subtitle2">Presets</Typography>
          <FieldArray
            name="preset"
            render={arrayHelpers => (
              <div>
                {values.preset && values.preset.length > 0 ? (
                  values.preset.map((_, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <Field
                          component={TextField}
                          label={`Field Name #${index}`}
                          name={`preset.${index}.field`}
                        />
                        <Field
                          component={TextField}
                          label={`Value #${index}`}
                          name={`preset.${index}.value`}
                        />
                      </div>
                      <DeleteIconButton
                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                      />
                      <button
                        type="button"
                        onClick={arrayInsert(arrayHelpers, index)} // insert an empty string at a position
                      >
                        +
                      </button>
                    </div>
                  ))
                ) : (
                  <button
                    type="button"
                    onClick={() => arrayHelpers.push({ field: "", value: "" })}
                  >
                    Add New Preset
                  </button>
                )}
              </div>
            )}
          />
        </>
      )
    case "fieldArray":
    case "addressBlock":
    case "checkbox":
    case "yesNo":
    case "text":
    case "textField":
    case "switch":
    case "number":
      return (
        <>
          <Field label="Field Name" component={TextField} name="name" />
          <Field
            component={Checkbox}
            name="affectsPremium"
            label="Affects Premium?"
          />
        </>
      )
    case "radioButton":
    case "dropdown":
      return (
        <>
          <Field label="Field Name" component={TextField} name="name" />
          <Field
            component={Checkbox}
            name="affectsPremium"
            label="Affects Premium?"
          />
          <FieldArray
            name="options"
            render={arrayHelpers => (
              <div>
                {values.options && values.options.length > 0 ? (
                  values.options.map((_, index) => (
                    <div
                      key={index}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <Field
                          component={TextField}
                          label={`Drop Value#${index}`}
                          name={`options.${index}.value`}
                        />
                        <Field
                          component={TextField}
                          label={`Drop Display#${index}`}
                          name={`options.${index}.display`}
                        />
                      </div>
                      <DeleteIconButton
                        onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                      />
                      <button
                        type="button"
                        onClick={arrayInsert(arrayHelpers, index)} // insert an empty string at a position
                      >
                        +
                      </button>
                    </div>
                  ))
                ) : (
                  <Button
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({ value: "", display: "" })
                    }
                  >
                    {/* show this when user has removed all friends from the list */}
                    Add Dropdown Option
                  </Button>
                )}
              </div>
            )}
          />
        </>
      )
    case "date":
      return (
        <>
          <Field label="Field Name" component={TextField} name="name" />
          <Field
            component={Checkbox}
            name="affectsPremium"
            label="Affects Premium?"
          />
        </>
      )
    default:
      return <div>No Attributes for this Type</div>
  }
}

export default connect(InputAttributes)

import React from "react"
import { useStateValue } from "../../../state"
import { Field, FieldArray as FieldArrayFormik, connect } from "formik"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import TextField from "../../forms/Inputs/textField"
import Select from "../../forms/Inputs/select"
import ArrayTextField from "../../forms/Inputs/array"
import DeleteIconButton from "../../partials/deleteIconButton"

import Preview from "../../../api/preview"
import pdfMakePrototype from "./pdfMakeProto"
// import CreateDocDefinition from "../../../util/pdf"
import SaveButton from "../../partials/saveButton"
import { saveProduct } from "../../../state/actions"

const blankTable = {
  headers: [],
  sections: [],
}

const blankSection = { items: [], title: "" }

const blankItem = {
  type: "text",
  value: "",
}

const blankPolicyLabel = {
  label: "",
  type: "text",
  value: "",
}

const policySource = [
  { display: "Text", value: "text" },
  { display: "Order Number", value: "orderNumber" },
  { display: "Address", value: "address" },
  { display: "Effective Date", value: "effectiveDate" },
  { display: "Date", value: "date" },
]

const isFieldArraySource = [
  { display: "Field Array", value: "fieldArray" },
  { display: "Field Array - Itemized", value: "fieldArrayItemized" },
  { display: "Field Array - Sum", value: "fieldArraySum" },
  { display: "Lookup", value: "lookup" },
  { display: "Text", value: "text" },
]

const arrayInsert = (helper, index, object) => () =>
  helper.insert(index + 1, object)

async function PreviewPdf(productId, schema) {
  const docDefinition = await Preview.getDocDefinition(productId, schema)
  pdfMakePrototype(docDefinition)
  console.log("docDefinition received: ", docDefinition)
}

function CreateBlankRow(numColumns) {
  return Array(parseInt(numColumns)).fill(blankItem)
}

function PdfMakeInputs({ formik, ...rest }) {
  const { values } = formik
  const [state, dispatch] = useStateValue()
  const { id: productId } = state.editProduct

  const handleSave = values => {
    if (!state.editProduct.id) {
      console.log("ERROR: no product selected")
      return
    }

    const newState = { ...state.editProduct, pdf_schema: values }
    dispatch(saveProduct(newState))
  }

  return (
    <>
      <FieldArrayFormik
        name="policy"
        render={arrayHelpers => (
          <div>
            {values.policy && values.policy.length > 0 ? (
              values.policy.map((_, index) => (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Grid container key={index}>
                    <Grid item xs={4}>
                      <Field
                        component={TextField}
                        label={`Point#${index} Label`}
                        name={`policy.${index}.label`}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={Select}
                        label="Type"
                        name={`policy.${index}.type`}
                        source={policySource}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Field
                        component={TextField}
                        label={`Point#${index} Value`}
                        name={`policy.${index}.value`}
                      />
                    </Grid>
                  </Grid>
                  <DeleteIconButton
                    onClick={() => arrayHelpers.remove(index)}
                  />
                  <Button
                    type="button"
                    onClick={arrayInsert(arrayHelpers, index, blankPolicyLabel)}
                  >
                    +
                  </Button>
                </div>
              ))
            ) : (
              <button
                type="button"
                onClick={() => arrayHelpers.push({ description: "" })}
              >
                Add Policy Label
              </button>
            )}
          </div>
        )}
      />
      <h2>Coverages</h2>
      <FieldArrayFormik
        name="tables"
        render={arrayHelpers => (
          <div>
            {values.tables && values.tables.length > 0 ? (
              values.tables.map((table, index) => (
                <div key={index}>
                  <div>{`Table #${index}`}</div>
                  <Field
                    component={TextField}
                    label="Number of Columns"
                    name={`tables.${index}.numColumns`}
                  />
                  <Button variant="contained">Initialize Rows</Button>
                  <Field
                    component={ArrayTextField}
                    label="Headers"
                    name={`tables.${index}.headers`}
                  />
                  <div>Sections</div>
                  <FieldArrayFormik
                    name={`tables.${index}.sections`}
                    render={sectionHelpers => (
                      <div>
                        {table.sections && table.sections.length > 0 ? (
                          table.sections.map((section, sectionIndex) => (
                            <div>
                              <Field
                                component={TextField}
                                label="Section Title"
                                name={`tables.${index}.sections.${sectionIndex}.title`}
                              />
                              <div>Rows</div>
                              <FieldArrayFormik
                                name={`tables.${index}.sections.${sectionIndex}.items`}
                                render={rowHelpers => (
                                  <div>
                                    {section.items &&
                                    section.items.length > 0 ? (
                                      <div>
                                        {section.items.map((row, rowIndex) => (
                                          <div>
                                            <div>{`Row#${rowIndex}`}</div>
                                            {row.map((item, itemIndex) => (
                                              <div>
                                                {`Item#${itemIndex}`}
                                                <Field
                                                  component={Select}
                                                  label="Element Type"
                                                  name={`tables.${index}.sections.${sectionIndex}.items.${rowIndex}.${itemIndex}.type`}
                                                  source={isFieldArraySource}
                                                />
                                                <Field
                                                  component={TextField}
                                                  label="Value"
                                                  name={`tables.${index}.sections.${sectionIndex}.items.${rowIndex}.${itemIndex}.value`}
                                                />
                                              </div>
                                            ))}
                                            <Button
                                              type="button"
                                              variant="outlined"
                                              color="error"
                                              onClick={() =>
                                                rowHelpers.remove(index)
                                              }
                                            >
                                              Delete Row
                                            </Button>
                                            <Button
                                              type="button"
                                              variant="outlined"
                                              onClick={arrayInsert(
                                                rowHelpers,
                                                rowIndex,
                                                CreateBlankRow(table.numColumns)
                                              )}
                                            >
                                              Insert Row
                                            </Button>
                                          </div>
                                        ))}
                                      </div>
                                    ) : (
                                      <button
                                        type="button"
                                        onClick={() =>
                                          rowHelpers.push(
                                            CreateBlankRow(table.numColumns)
                                          )
                                        }
                                      >
                                        Add a Row
                                      </button>
                                    )}
                                  </div>
                                )}
                              />
                              <Button
                                type="button"
                                variant="outlined"
                                color="error"
                                onClick={() => sectionHelpers.remove(index)}
                              >
                                Delete Section
                              </Button>
                              <Button
                                type="button"
                                variant="outlined"
                                onClick={arrayInsert(
                                  sectionHelpers,
                                  sectionIndex,
                                  blankSection
                                )}
                              >
                                Insert Section
                              </Button>
                            </div>
                          ))
                        ) : (
                          <button
                            type="button"
                            onClick={() => sectionHelpers.push(blankSection)}
                          >
                            Add a Section
                          </button>
                        )}
                      </div>
                    )}
                  />
                  <Button
                    type="button"
                    variant="outlined"
                    color="error"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    Delete Table
                  </Button>
                  <Button
                    type="button"
                    variant="outlined"
                    onClick={arrayInsert(arrayHelpers, index, blankTable)}
                  >
                    Insert Table
                  </Button>
                </div>
              ))
            ) : (
              <button
                type="button"
                onClick={() => arrayHelpers.push(blankTable)}
              >
                Add a Table
              </button>
            )}
            <button
              type="button"
              onClick={() => {
                PreviewPdf(productId, values)
              }}
            >
              Generate
            </button>
            <SaveButton onClick={() => handleSave(values)} />
            <div id="canvas" />
          </div>
        )}
      />
    </>
  )
}

export default connect(PdfMakeInputs)

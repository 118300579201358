import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import Check from "@material-ui/icons/Check"
import Warning from "@material-ui/icons/Warning"
import withStyles from "@material-ui/styles/withStyles"
import SvgIcon from "@material-ui/core/SvgIcon"
import useTheme from "@material-ui/styles/useTheme"
import styled from "styled-components"

export const styles = theme => ({
  /* Styles applied to the root element. */
  root: {
    display: "block",
    color: "rgba(140, 140, 140, 1)",
    "&$active": {
      color: theme.palette.primary.main,
    },
    "&$completed": {
      color: theme.palette.primary.main,
    },
    "&$error": {
      color: theme.palette.error.main,
    },
  },
  /* Styles applied to the SVG text element. */
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily,
  },
  /* Pseudo-class applied to the root element if `active={true}`. */
  active: {},
  /* Pseudo-class applied to the root element if `completed={true}`. */
  completed: {},
  /* Pseudo-class applied to the root element if `error={true}`. */
  error: {},
})

const IconContainer = styled.div`
  position: relative;
  z-index: 5000;
`

const StepIcon = React.forwardRef(function StepIcon(props, ref) {
  const {
    completed = false,
    icon,
    active = false,
    error = false,
    classes,
  } = props

  const theme = useTheme()
  const iconProps = {
    className: clsx(classes.root),
    style: {
      position: "absolute",
      top: "2px",
      left: "2px",
      color: theme.palette.primary.contrastText,
    },
    fontSize: "small",
    ref,
  }

  if (typeof icon === "number" || typeof icon === "string") {
    if (error) {
      return <Warning className={clsx(classes.root, classes.error)} ref={ref} />
    }
    if (completed) {
      return (
        <IconContainer>
          <SvgIcon className={clsx(classes.root, classes.completed)} ref={ref}>
            <circle cx="12" cy="12" r="12" />
          </SvgIcon>
          <Check {...iconProps} />
        </IconContainer>
      )
    }
    return (
      <IconContainer>
        <SvgIcon
          className={clsx(classes.root, {
            [classes.active]: active,
          })}
          ref={ref}
        >
          <circle cx="12" cy="12" r="12" />
          <text className={classes.text} x="12" y="16" textAnchor="middle">
            {icon}
          </text>
        </SvgIcon>
      </IconContainer>
    )
  }

  return (
    <IconContainer>
      <SvgIcon
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
        ref={ref}
      >
        <circle cx="12" cy="12" r="12" />
      </SvgIcon>
      {React.cloneElement(icon, iconProps)}
    </IconContainer>
  )
})

StepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Override or extend the styles applied to the component.
   * See [CSS API](#css) below for more details.
   */
  classes: PropTypes.object.isRequired,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * Mark the step as failed.
   */
  error: PropTypes.bool,
  /**
   * The icon displayed by the step label.
   */
  icon: PropTypes.node.isRequired,
}

export default withStyles(styles, { name: "MuiStepIcon" })(StepIcon)

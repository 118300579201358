import React from "react"
import { Elements, injectStripe, StripeProvider } from "react-stripe-elements"
import CheckoutForm from "./checkoutForm"
const InjectedCheckoutForm = injectStripe(CheckoutForm)

export default class StripeElements extends React.Component {
  state = {
    stripe: null,
  }

  componentDidMount() {
    const stripeKey = process.env.STRIPE_PUBLIC_KEY
    const stripeUrl = "https://js.stripe.com/v3/"
    if (!document.querySelector("#stripe-js")) {
      const script = document.createElement("script")
      script.async = true
      script.id = "stripe-js"
      script.onload = () => {
        const stripe = window.Stripe(stripeKey)
        this.setState({ stripe: stripe })
      }
      document.head.appendChild(script)
      script.src = stripeUrl
    } else if (window.Stripe) {
      const stripe = window.Stripe(stripeKey)
      this.setState({ stripe: stripe })
    }
  }

  render() {
    return (
      // <Container maxWidth="md">
      // <Grid item xs={12}>
      <StripeProvider stripe={this.state.stripe}>
        <Elements>
          <InjectedCheckoutForm
            stripe={this.state.stripe}
            product={this.props.product}
          />
        </Elements>
      </StripeProvider>
      // </Grid>
      // </Container>
    )
  }

  onFieldBlur = fieldSelector => () => {
    const selected = document.querySelector(`#card-${fieldSelector} div`)
    if (
      selected.classList.contains("StripeElement--complete") &&
      this.formComplete()
    ) {
      this.submit()
    }
  }
}
